import {useState} from "react";

function ManageTask(props) {

    console.log("DEBUG=",props.taskData?.taskFlagSolved);

    const [ taskTitle, setTaskTitle ] = useState(props.taskData?.title ?? "");
    const [ taskDescription, setTaskDescription ] = useState(props.taskData?.descr ?? "");
    const [ taskFlagSolved, setTaskFlagSolved ] = useState(props.taskData?.flagSolved ?? false);

    const showMode = props.mode; //{"create","edit"}

    //TODO place styles in css
    const style = {
        position: "fixed",
        top: "10px",
        backgroundColor: "white",
        border: "solid black 1px",
        borderRadius: "10px",
        padding: "20px",
        margin: "auto",
        left: "0",
        right: "0",
        top: "5%",
        width: "60%",
        //height: "40%",

    };

    function onClickCreateModify(event) {
        console.log("ManageTask::onClickCreateModify()");
        event.preventDefault();

        if (showMode==="create") {
            //Acquire new task values
            props.onCreateModify({
                mode: "create",
                taskTitle,
                taskDescription,
                taskFlagSolved
            });
        } else {
            props.onCreateModify({
                mode: "edit",
                id: props.taskData.id,
                taskTitle,
                taskDescription,
                taskFlagSolved
            });
        }
        
    }

    function onClickSolved() {
        setTaskFlagSolved(!taskFlagSolved);
    }

    function onClickCancel() {
        console.log("ManageTask::onClickCancel()");
        props.onCancel();
    }

    return (
        <div style={style}>
            <form className="manage_task" onSubmit={onClickCreateModify}>
                <h2 style={{textAlign: "center"}}>{showMode==="create" ? "Create new Dutu" : "Edit Dutu"}</h2>
                <hr></hr>
                <div class="form-group">
                    <input
                        className="form-control font-weight-bold"
                        type="text" 
                        id="task_title"
                        name="task_title"
                        value={ taskTitle }
                        onChange={ e=>setTaskTitle(e.target.value) }
                        ></input>
                    <label htmlFor="task_title">Task title</label>
                </div>
                <br></br>

                <div class="form-group">
                    <textarea
                        className="form-control"
                        id="task_description"
                        name="task_description"
                        rows="4" cols="10"
                        value={ taskDescription }
                        onChange={e=>setTaskDescription(e.target.value)} />
                    <label htmlFor="task_description">Task description</label>
                    <br></br>
                </div>

                <div class="form-group">
                    <div className="form-check">
                        {taskFlagSolved ? 
                            <input className="form-check-input" type="checkbox" id="managetask_task_solved" name="managetask_task_solved" onClick={onClickSolved} checked></input> :
                            <input className="form-check-input" type="checkbox" id="managetask_task_solved" name="managetask_task_solved" onClick={onClickSolved}></input>
                        }
                        <label className="form-check-label" htmlFor="managetask_task_solved">Solved</label>
                    </div>
                    <br></br>
                </div>

                <button className="btn btn-danger" type="button" onClick={onClickCancel}> Cancel </button>
                <input className="btn btn-success" type="submit" value={showMode==="create" ? "Create" : "Save"} />
            </form>
        </div>
    );
}

export default ManageTask;
