import {Component} from "react";

class Task extends Component {
    state = {};

    callbackDelete=null;
    callbackEdit=null;
    callbackSolve=null;
    taskID=null;

    constructor(props) {
        super(props);
        console.log("Task::Task()");
        //TODO mb extract taskID from state
        this.taskID=props.taskID;
        this.callbackDelete=props.callbackDelete;
        this.callbackEdit=props.callbackEdit;
        this.callbackSolve=props.callbackSolve;
        this.state.flagSolved = props.flagSolved;

        this.onClickDelete=this.onClickDelete.bind(this);
        this.onClickEdit=this.onClickEdit.bind(this);
        this.onClickSolved=this.onClickSolved.bind(this);
    }

    // componentDidUpdate(prevProps){
    //     console.log("componentDidUpdate()");
    //     console.log("\tprevProps=", prevProps);
    //     console.log("\tthis.state=", this.state);


    //     if(prevProps.flagSolved !== this.state.flagSolved){
    //         console.log("\tUpdate flagSolved");
    //         this.state.flagSolved=prevProps.flagSolved;
    //     }
    // }

    componentWillUpdate(nextProps){
        console.log("componentWillUpdate()");

        if(nextProps.flagSolved !== this.state.flagSolved){
            this.state.flagSolved=nextProps.flagSolved;
        }
    }

    onClickDelete() {
        console.log("Task::onClickDelete()");
        console.log("\ttaskID=", this.taskID);
        this.callbackDelete(this.taskID);
    }

    onClickEdit() {
        console.log("Task::onClickEdit()");
        this.callbackEdit(this.taskID);
    }

    onClickSolved() {
        console.log("Task::onClickSolved()");
        console.log("\tthis.state.flagSolved=",this.state.flagSolved);
        console.log("\tthis.taskID=",this.taskID);
        this.callbackSolve(this.taskID, !this.state.flagSolved);
    }

    render () {
        return (
            <div className={"card my-3 " + (this.state.flagSolved ? "bg-dark text-light" : "bg-light text-dark") }>
                <div className="card-header text-center"> <h5> {this.props.title} </h5> </div>
                <div className="card-body m-2 ">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-9">
                            <p className="card-text"> {this.props.description} </p>
                        </div>
                    
                        <div className="div_task_manage col-sm-3">
                            <div className="div_task_manage_inner">
                            <div className="form-check">
                                {<input className="form-check-input" type="checkbox" name="task_solved" onClick={this.onClickSolved} checked={this.state.flagSolved}></input>}
                                <label className="form-check-label" htmlFor="task_solved">Solved</label>
                            </div>
                            <br></br>

                            <div>
                                <button style={{width: "100%"}} className="btn btn-warning m-1" type="button" onClick={this.onClickEdit}>Edit</button>
                                <button style={{width: "100%"}} className="btn btn-danger m-1" type="button" onClick={this.onClickDelete}>Delete</button>
                            </div>
                            <br></br>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="card-footer">
                    <i>Task creation happened in {this.props.creationDate}</i>
                </div>
            </div>
        );
    }
};

export default Task;
