import React, {Component} from "react";

import Task from "./Task";
import ManageTask from "./ManageTask";

import configData from "../config.js";

class Main extends Component {

    state = {};

    constructor(props) {
        super(props);

        console.log("Main.Main()");

        this.state.tasks = [];

        this.state.flagShowManageTask = false;
        this.state.manageTaskMode=null;
        this.state.manageTaskInfo=null;

        this.onCreateTask = this.onCreateTask.bind(this);
        this.onManageTaskCreate = this.onManageTaskCreate.bind(this);
        this.onManageTaskCancel = this.onManageTaskCancel.bind(this);
        
        this.onManageTaskEdit = this.onManageTaskEdit.bind(this);
        this.onTaskEdit = this.onTaskEdit.bind(this);
        this.onTaskDelete = this.onTaskDelete.bind(this);
        this.onTaskSolved = this.onTaskSolved.bind(this);
    }

    componentDidMount(){
        console.log("Main.componentDidMount()");

        let xhr = new XMLHttpRequest();
        
        xhr.onload =
            () => {
                console.log(`xhr.status=${xhr.status}`);
                if (xhr.status == 200) {
                    console.log("get_tasks_list request succeeded");

                    const tasksData = JSON.parse(xhr.response);
                    console.log("tasksData: ", tasksData);
                    this.setState( {tasks: tasksData} );
                } else {
                    alert("ERROR: Failed to load tasks");
                }
            }
        ;

        //xhr.open("GET", configData.requestURL + "/api/get_tasks_list", true);
        xhr.open("GET", "api/get_tasks_list", true);
        console.log(xhr);
        xhr.send(null);
    }

    onCreateTask() {
        console.log("Main.onCreateTask()");

        //Create "Create task" window
        this.setState({
            manageTaskMode: "create",
            flagShowManageTask: true
        });
    }

    onManageTaskCreate(taskAttributes) {
        console.log("Main.onManageTaskCreate()");

        console.log("taskAttributes: ", taskAttributes);

        //title,descr,flagSolved
        let rq = new XMLHttpRequest();
        //rq.open("POST", "http://localhost:8888/api/task", true);
        
        rq.onerror=()=>{console.log("onerror"); alert("ERROR: Failed to create task");}
        rq.onabort=()=>{console.log("onabort"); alert("ERROR: Failed to create task");}
        rq.onload = () => {
                console.log(`rqLogin.status=${rq.status}`);
                if (rq.status == 200) {
                    console.log("rqLogin request succeeded");

                    const rqRes = JSON.parse(rq.response);
                    this.state.tasks.unshift({
                        id: rqRes.taskData.taskID,
                        title: taskAttributes.taskTitle,
                        descr: taskAttributes.taskDescription,
                        flagSolved: taskAttributes.taskFlagSolved,
                        creationDate: rqRes.taskData.creationDate,
                    });

                    //invoke create task
                    this.setState({
                        tasks: this.state.tasks,
                        flagShowManageTask: false,
                    });
                } else {
                    alert("ERROR: Failed to create task");
                }
        }
        
        //TODO Investigate what the fuck is this
        rq.open("POST", configData.requestURL + "/api/task", true);
        rq.setRequestHeader("Content-Type", "application/json");
        rq.setRequestHeader("Content-Type", "text/plain");
        rq.send(JSON.stringify({
            title: taskAttributes.taskTitle,
            descr: taskAttributes.taskDescription,
            flagSolved: taskAttributes.taskFlagSolved,
        }));
    }

    onManageTaskCancel() {
        this.setState({flagShowManageTask: false});
    }

    onTaskEdit(taskID) {
        //Find taskID in this.state.tasks
        //pass task description
        //TODO mb store tasks in map<taskID:taskInfo>
        let findIndex = null;
        for (let i=0; i!=this.state.tasks.length; ++i) {
            if (this.state.tasks[i].id === taskID) {
                findIndex = i;
                break;
            }
        }

        this.setState({
            manageTaskMode: "edit",
            manageTaskInfo: this.state.tasks[findIndex],
            flagShowManageTask: true,
        });
    }

    onManageTaskEdit(taskData) {
        console.log("onManageTaskEdit()");

        console.log("taskData=", taskData);

        //Determine only diff values
        let findIndex = null;
        for (let i=0; i!=this.state.tasks.length; ++i) {
            if (this.state.tasks[i].id === taskData.id) {
                findIndex = i;
                break;
            }
        }
        let diffData={};
        if (taskData.taskTitle!=this.state.tasks[findIndex].title) { console.log("!title"); diffData.title=taskData.taskTitle; }
        if (taskData.taskDescription!=this.state.tasks[findIndex].descr) { console.log("!descr"); diffData.descr=taskData.taskDescription; }
        if (taskData.taskFlagSolved!=this.state.tasks[findIndex].flagSolved) { console.log("!flagSolved"); diffData.flagSolved=taskData.taskFlagSolved; }

        console.log(Object.keys(diffData).length);

        if (0===Object.keys(diffData).length) {
            this.setState({flagShowManageTask: false});
            return;
        }

        console.log("diffData=",diffData);
        diffData.taskID=taskData.id;
        // //Send diff data
        // const response = await fetch(`http://localhost:8888/api/task/${taskData.id}`,{
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json;charset=UTF-8",
        //     },
        //     body: ,
        // });

        let rq = new XMLHttpRequest();
        rq.open("PUT", configData.requestURL + `/api/task/${taskData.id}`, true);
        rq.setRequestHeader("Content-Type", "application/json");
        rq.setRequestHeader("Content-Type", "text/plain");
        rq.onload = () => {
            console.log("onload");
            if (rq.status == 200) {
                console.log("status==200");
                if (diffData.title) { this.state.tasks[findIndex].title=diffData.title; }
                if (diffData.descr) { this.state.tasks[findIndex].descr=diffData.descr; }
                if (diffData.flagSolved!=undefined) { this.state.tasks[findIndex].flagSolved=diffData.flagSolved; }
                console.log(diffData.flagSolved);
                console.log(this.state.tasks);
                //rerender (here it happens nevertheless)

                //Only close if request succeeds
                //Change data of appropriate task
                this.setState({
                    tasks:this.state.tasks,
                    flagShowManageTask: false
                });
            } else {
                alert("ERROR: Failed to save task data");
            }
        }
        rq.onreadystatechange = function() {
            if (rq.readyState == XMLHttpRequest.DONE ) {
               if(rq.status == 200){
                  console.log("asdfas",rq.responseText);
               }
    
            }
        }
        rq.send(JSON.stringify(diffData));
    }

    onTaskDelete(taskID) {
        console.log("onTaskDelete tasks:", this.state.tasks);

        let rq = new XMLHttpRequest();
        rq.open("DELETE", configData.requestURL + `/api/task/${taskID}`, true);
        
        

        rq.onload = () => {
                console.log(`rqLogin.status=${rq.status}`);
                if (rq.status == 200) {
                    console.log("rq delete request succeeded");

                    const loginData = JSON.parse(rq.response);
                    if (loginData.result) {
                        console.log("before delete", this.state.tasks);
                        for (let i =0; i!=this.state.tasks; ++i){
                            if (this.state.tasks[i].id === taskID) {
                                this.state.tasks.splice(i,1);
                                this.setState({tasks: this.state.tasks});
                                console.log("delete");
                                console.log("after delete", this.state.tasks);
                                break;
                            }
                        }
                    } else {

                    }
                }
            };

        rq.send(null);
    }

    onTaskSolved(taskID, taskStatus) {
        let rq = new XMLHttpRequest();
        rq.open("PUT", configData.requestURL + `/api/task/${taskID}`, true);
        rq.setRequestHeader("Content-Type", "application/json");
        rq.setRequestHeader("Content-Type", "text/plain");
        rq.onload = () => {
            console.log("onload");
            if (rq.status == 200) {
                let findIndex = null;
                for (let i=0; i!=this.state.tasks.length; ++i) {
                    if (this.state.tasks[i].id === taskID) {
                        findIndex = i;
                        break;
                    }
                }
                this.state.tasks[findIndex].flagSolved=taskStatus;
                this.setState({
                    tasks: this.state.tasks,
                });
            } else {
                alert("ERROR: Failed to save task data");
            }
        }

        console.log("taskID=",taskID);
        console.log("taskStatus=",taskStatus);


        rq.send(JSON.stringify({
            taskID: taskID,
            flagSolved: taskStatus,
        }));
    }

    render() {
        console.log("Tasks count: ", this.state.tasks.length);

        const tasks = this.state.tasks;
        console.log("render Tasks:", tasks);
        //TODO show something if no tasks

        // if (!this.state.tasks.length) {
        //     console.log("this.state.tasks.length == 0");
        //     return <h2> Loading... </h2>;
        // }

        return (
            <div className="element_main">
                <div className="main_sidebar">
                    <div className="div_sidebar_logo"><img src="./logo_sidebar.gif" /></div>
                    {/* <div>Calender (under dev)</div>
                    <div>(Maybe something else)</div> */}
                </div>

                <div className="main_contents mx-5">
                    <nav className="mainNavbar mt-4 mb-5">
                        <span> Howdy, <b>{this.props.userData.login}</b>! </span>
                    </nav>

                    <button
                        type="button"
                        className="btn btn-success superButton my-5"
                        onClick={this.onCreateTask}
                    >
                        <h1>+</h1>
                    </button>

                    <h3>Your tasks:</h3>
                    <hr></hr>

                        {this.state.tasks.map(
                            e => (
                                <Task
                                    key={e.id}
                                    taskID={e.id}
                                    // TODO place title/description/falgsSolved/creationDate in single object
                                    title={e.title}
                                    description={e.descr}
                                    flagSolved={e.flagSolved ? true : false}
                                    creationDate={e.creationDate}
                                    callbackDelete={this.onTaskDelete}
                                    callbackEdit={this.onTaskEdit}
                                    callbackSolve={this.onTaskSolved}
                                />
                            )
                        )}

                    {this.state.flagShowManageTask && (
                        <>
                            <div style={{position: "fixed", backgroundColor: "black", opacity: "0.5", top: "0",right: "0", width: "100%",height: "100%", minHeight: "100%"}} onClick={this.onManageTaskCancel}></div>
                        
                            <ManageTask
                                mode={ this.state.manageTaskMode }
                                taskData={this.state.manageTaskMode === "create" ? undefined : this.state.manageTaskInfo }
                                onCancel={ this.onManageTaskCancel }
                                onCreateModify={this.state.manageTaskMode === "create" ? this.onManageTaskCreate : this.onManageTaskEdit }
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }
    
}

export default Main;
