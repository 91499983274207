import {useState} from "react";

import configData from "../config.js";

function Login(props) {

    let [curMode, setCurMode] = useState("login");

    function onSubmitLogin(ev) {
        console.log("onSubmit()");

        ev.preventDefault();
        
        const formLogin = document.getElementById("formLogin");

        let rqLogin = new XMLHttpRequest();
        rqLogin.open("POST", configData.requestURL + "/login", true);
        rqLogin.onload =
            () => {
                console.log(`rqLogin.status=${rqLogin.status}`);
                if (rqLogin.status == 200) {
                    console.log("rqLogin request succeeded");
                    const loginData = JSON.parse(rqLogin.response);
                    if (loginData.result) {
                        //const clientSessID = loginData;
                        const userData = {
                            login: formLogin.elements["formLogin_fieldLogin"].value,
                        };
                        props.callback(userData);
                    } else {
                        alert("ERROR: Failed to login");
                    }
                    
                }
        }

        rqLogin.send(new FormData(formLogin));
    }


    function onSubmitRegister(ev) {
        console.log("onSubmitRegister()");

        ev.preventDefault();
        
        const formRegister = document.getElementById("formRegister");

        let rq = new XMLHttpRequest();
        rq.open("POST", configData.requestURL + "/register", true);
        //rqLogin.open("POST", "yandex.ru");
        rq.onload =
            () => {
                console.log(`rqRegister.status=${rq.status}`);
                if (rq.status == 200) {
                    console.log("rq register request succeeded");

                    const registerData = JSON.parse(rq.response);
                    if (registerData.result) {
                        //const clientSessID = registerData;
                        const userData = {
                            login: formRegister.elements["formRegister_fieldLogin"].value,
                        };
                        props.callback(userData);
                    } else {
                        alert("ERROR: Failed to register (maybe provided login is already registered)");
                    }
                    
                } else {
                    alert("ERROR: Failed to register (maybe provided login is already registered)");
                }
        }

        rq.send(new FormData(formRegister));
    }

//TODO mb create separate Login and Register components
    function renderFragmentLogin() {
        return (
            <form id="formLogin" action="/login" method="POST" onSubmit={onSubmitLogin}>
                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                    <li key="login" className="nav-item col-sm-6 active" role="presentation">
                        <a className="nav-link active" aria-current="page" >Login</a>
                    </li>

                    <li key="register" className="nav-item col-sm-6" role="presentation">
                        <a className="nav-link" onClick={()=>setCurMode("register")}>Register</a>
                    </li>
                </ul>
                <br></br>

                <div className="form-group">
                    <input type="text" className="form-control" id="formLogin_fieldLogin" name="formLogin_fieldLogin" placeholder="Login"/>
                </div>
                <br></br>
                        
                <div className="form-group">
                    <input type="password" className="form-control" id="formLogin_fieldPassword" name="formLogin_fieldPassword" placeholder="Password" />
                </div>
                <br></br>

                <button className="btn btn-primary form-control" type="submit">Login</button>
            </form>
        );
    }

    function renderFragmentRegister(){
        console.log("renderFragmentRegister()");
        return (
            <>
                <form id="formRegister" action="/register" method="POST" onSubmit={onSubmitRegister}>
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li key="login" className="nav-item col-sm-6" role="presentation">
                            <a className="nav-link" onClick={()=>setCurMode("login")}>Login</a>
                        </li>

                        <li key="register" className="nav-item col-sm-6 active" role="presentation">
                            <a className="nav-link active" aria-current="page">Register</a>
                        </li>
                    </ul>
                    <br></br>

                    <div className="form-group">
                        <input type="text" className="form-control" id="formRegister_fieldLogin" name="formRegister_fieldLogin" placeholder="Login"/>
                    </div>
                    <br></br>
                            
                    <div className="form-group">
                        <input type="password" className="form-control" id="formRegister_fieldPassword" name="formRegister_fieldPassword" placeholder="Password" />
                    </div>
                    <br></br>

                    <div className="form-group">
                        <input type="email" className="form-control" id="formRegister_fieldEmail" name="formRegister_fieldEmail" placeholder="Email" />
                    </div>
                    <br></br>

                    <button className="btn btn-primary form-control" type="submit">Register</button>
                </form>

                
            </>
        );
    }

    return (
        <div className="mx-5">
            <div className="mb-5 text-center">
                <img className="text-center" src={"./logo_main.gif"} />
            </div>

            <div className="my-5">
                <h1 align="center"><b>DuTu</b></h1>     
                <h5 align="center">The sexiest ToDo-manager in the humanity timeline (thanks to Pepe)</h5>
                <hr />
            </div>
            <div className="m-5 sticky-bottom"></div>
            {curMode === "login" ? renderFragmentLogin() : renderFragmentRegister()}
        </div>
    );
}

export default Login;
