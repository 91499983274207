import {useState} from "react";

import Login from "./Login";
import Main from "./Main";

function App(props) {
    let [curComponent, changeCurComponent] = useState(<Login callback={onLogin}/>);

    function onLogin(userData){
        console.log("onLogin()");
        changeCurComponent(<Main userData={userData}/>);
    }

    return (
        <>
            {curComponent}
        </>
    );
}

export default App;
